import React from 'react'
import Slider from '../components/Slider';
const Home = () => {
  return (
    <div>
        
        <Slider/>
    </div>
  )
}

export default Home
